.center-image{
  display:flex;
  justify-content:center; /* horizontally center */
  align-items:center;    /* vertically center */
  height:300px;
  width:300px;
  margin-left: auto;
  margin-right: auto;
  
  /* overflow: "hidden"; */
}

.center-image img{
 max-width:100%;
 max-height:100%;
 border-radius: 50%;
}

.text-center {
  /* justify-content: center; */
  text-align: center;
  color: white;
}

.singleCol {
  max-width: 750px;
  margin: 0 auto;
}

.social-media-icons a {
  font-size: 2rem;
  justify-content: space-evenly;
}

.social-media-icons p {
  font-size: 1rem;
  justify-content: space-evenly;
}


.description {
  padding-left: 5%;
  padding-right: 5%;
}

#home {
  background: rgb(38,38,38);
  background: linear-gradient(0deg, rgba(38,38,38,1) 0%, rgba(42,64,83,1) 100%);
}


.homeLogo {
  height: 50px;
  width: 50px;
  /* border-radius: 50%; */
}

.cardProjects h2{
  text-align: center;
}

.cardImages {
  height: 168px;
  width: 280px;
}